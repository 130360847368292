import Metadata from '~/components/Metadata'

import ContentPage from '~/components/Content/Page'
import { getPage } from '../graphql-cms/pages'
// import { shopifyClient } from '~/services/shopify'

export async function getStaticProps({ locale }) {
  const page = await getPage({ locale, slug: 'home' })

  // Iterate over the products in heroModule.collection (contentful) and fetch the closing date for each product from Shopify metafields
  // if (
  //   page?.heroModule?.collection &&
  //   page?.heroModule?.collection?.productsCollection?.items?.length > 0
  // ) {
  //   let earliestClosingProductTimestamp = null
  //   let latestClosingProductTimestamp = null

  //   for (const item of page?.heroModule?.collection?.productsCollection
  //     ?.items) {
  //     const shopifyProduct = await shopifyClient({
  //       query: `{
  //         products(first: 1, query: "title:'${item.name}'") {
  //           edges {
  //             node {
  //               id
  //               title
  //               closingDate: metafield(key: "closed", namespace: "basta") {
  //                 value
  //               }
  //             }
  //           }
  //         }
  //       }`
  //     })

  //     const shopifyProductClosingDate =
  //       shopifyProduct?.data?.products?.edges[0]?.node?.closingDate?.value

  //     const shopifyProductClosingDateTimestamp = new Date(
  //       shopifyProductClosingDate
  //     )

  //     if (!earliestClosingProductTimestamp)
  //       earliestClosingProductTimestamp = shopifyProductClosingDateTimestamp

  //     if (!latestClosingProductTimestamp)
  //       latestClosingProductTimestamp = shopifyProductClosingDateTimestamp

  //     if (shopifyProductClosingDateTimestamp < earliestClosingProductTimestamp)
  //       earliestClosingProductTimestamp = shopifyProductClosingDateTimestamp

  //     if (shopifyProductClosingDateTimestamp > latestClosingProductTimestamp)
  //       latestClosingProductTimestamp = shopifyProductClosingDateTimestamp
  //   }

  //   page.heroModule.earliestClosingProductDate =
  //     earliestClosingProductTimestamp.toString()

  //   page.heroModule.latestClosingProductDate =
  //     latestClosingProductTimestamp.toString()
  // }

  return {
    props: {
      page
    }
  }
}

const HomePage = ({ page }) => {
  return (
    <>
      <Metadata title={page.name} />
      <ContentPage page={page} />
    </>
  )
}

export default HomePage
